<template>
  <q-card style="width: 600px">
    <div class="row items-center q-py-md q-px-lg">
      <q-space />
      <div class="text-h6 text-primary">
        {{ showFieldNotesForm ? 'Field Notes' : 'Farm Search' }}
      </div>
      <q-space />

      <q-btn icon="clear" color="primary" @click="handleClose" />
    </div>
    <q-separator color="primary" />

    <div v-if="!showFieldNotesForm" class="q-py-lg q-px-lg">
      <q-input
        outlined
        autofocus
        v-model="searchStr"
        label="Enter Farm"
        color="primary"
        @input="filterFarms()"
      />
    </div>
    <div v-if="showFieldNotesForm" class="q-py-lg q-px-lg">
      <div class="text-subtitle1 text-weight-medium">
        {{ selectedFarm.name }}
      </div>
      <div class="text-subtitle1">Add Daily Log/Diary</div>

      <div class="q-mb-md">
        <q-input
          outlined
          autofocus
          autogrow
          ref="fieldNotesInput"
          v-model="fieldNotes"
          label="Field Notes"
          color="primary"
        />
      </div>

      <q-select
        v-model="pondModel"
        outlined
        dense
        :options="pondOptions"
        label="Pond / PC"
        emit-value
        map-options
      />
    </div>
    <q-separator color="primary" />

    <div
      v-if="!showFieldNotesForm"
      class="scroll"
      :style="`max-height: ${store.state.pageHeight - 200}px`"
    >
      <div
        v-for="farm in farmsFiltered"
        :key="farm.name"
        @click="handleFarmSelect(farm)"
        class="row cursor-pointer"
      >
        <div class="col q-px-md q-py-xs">
          <div class="row items-center">
            <div class="text-subtitle1">
              {{ farm.name }}
            </div>
          </div>
        </div>
        <q-separator vertical />
        <q-separator class="full-width" />
      </div>
    </div>

    <div v-if="showFieldNotesForm" class="row justify-end q-py-md q-px-lg">
      <q-btn
        label="Cancel"
        color="grey-3"
        text-color="black"
        @click="handleCancel"
        class="q-mr-sm"
      />
      <q-btn
        label="Submit"
        color="primary"
        @click="submitFieldNotes"
        class="q-ml-sm"
      />
    </div>
  </q-card>
</template>

<script>
import { decoder } from '@/lib/helpers';
import store from '@/store';

export default {
  name: 'QuckAdd',
  data() {
    return {
      farms: [],
      farmsFiltered: [],
      fieldNotes: '',
      pondModel: null,
      searchStr: '',
      selectedFarm: null,
      showFieldNotesForm: false,
      store
    };
  },
  mounted() {
    this.setFarms();
  },
  methods: {
    subscribeFarm(farmId) {
      // First unsubscribe from current farm if needed
      this.unsubscribeFarm();

      // Then subscribe to the new farm
      return store.dispatch('subscribeFarm', farmId);
    },
    unsubscribeFarm() {
      const currFarmId = store.state.farm.id;

      if (currFarmId >= 0) {
        store.dispatch('unSubscribeFarm', currFarmId);
      }
    },
    filterFarms() {
      this.farmsFiltered.splice(0);

      const needle = this.searchStr.toLowerCase();

      for (const farm of this.farms) {
        if (farm.name.toLowerCase().includes(needle)) {
          this.farmsFiltered.push(farm);
        }
      }
    },
    handleCancel() {
      this.unsubscribeFarm();

      this.showFieldNotesForm = false;
    },
    handleClose() {
      this.$emit('close');
    },
    handleFarmSelect(farm) {
      this.selectedFarm = farm;
      this.showFieldNotesForm = true;

      // Subscribe to the farm immediately when selected
      this.subscribeFarm(farm.id);

      // Navigate to the farm page immediately if not already on the farm page
      if (this.$route.path !== `/farm/${farm.id}/work/`) {
        this.$router.push(`/farm/${farm.id}/work/`);
      }

      // Focus the field notes input after the DOM updates
      this.$nextTick(() => {
        if (this.$refs.fieldNotesInput) {
          this.$refs.fieldNotesInput.focus();
        }
      });
    },
    submitFieldNotes() {
      // Create a new worklog object
      const workLog = {
        id: 'new',
        work_cat: 12, // Daily Log category
        work_item: 105, // Notes item
        field_values: {},
        notes: this.fieldNotes,
        office_notes: '',
        billable: false,
        todo_flag: 0,
        todo_complete: null,
        todo_complete_by: null,
        date: Math.floor(Date.now() / 1000), // Current timestamp in seconds
        time: 0,
        pond_id: this.pondModel,
        computer_id: null,
        location_id: null,
        location_name: null,
        farm_id: this.selectedFarm.id,
        created_date: Math.floor(Date.now() / 1000),
        assigned_to: store.state.user.user_id,
        attachments: [],
        comments: [],
        office_comments: '',
        chats: [],
        last_chat_date: null,
        reminders: [],
        billed: 0,
        billed_changed_by: null,
        billed_changed_on: null
      };

      console.log('workLog', workLog);

      // Create the worklog
      store
        .dispatch('createWorkLog', {
          farmId: this.selectedFarm.id,
          log: workLog
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.submitFieldNotes();
            } else {
              // Close the dialog and reset form
              this.fieldNotes = '';
              this.pondModel = null;
              this.showFieldNotesForm = false;
              this.$emit('close');
            }
          });
        });
    },
    setFarms() {
      const farms = JSON.parse(localStorage.getItem('farms'));
      for (const farm of farms) {
        const farmName = decoder(farm.name);
        this.farms.push({
          name: farmName,
          id: farm.id
        });
        this.farmsFiltered.push({
          name: farmName,
          id: farm.id
        });
      }
    }
  },
  computed: {
    pondOptions() {
      const farmId = this.selectedFarm?.id;
      if (!farmId) return [];

      const farm = store.state.farmList.find((x) => x.id === farmId);
      if (!farm || !farm.gps || !farm.gps.ponds) return [];

      const pondList = [];
      const harvestBouys = [];

      for (const pond of farm.gps.ponds) {
        const pondName = pond.name.toLowerCase();

        if (pondName.includes('harvest') || pondName.includes('hb')) {
          harvestBouys.push({
            label: pond.name,
            value: pond.id,
            type: 'pond'
          });
        } else {
          pondList.push({
            label: pond.name,
            value: pond.id,
            type: 'pond'
          });
        }
      }

      // Sort pond numbers
      pondList.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );

      pondList.push({
        label: ' --- ',
        value: null,
        type: 'pond'
      });

      // Add harvest bouys after main ponds
      for (const bouy of harvestBouys) {
        pondList.push(bouy);
      }

      // Add computers if available
      if (farm.gps.computers) {
        for (const computer of farm.gps.computers) {
          pondList.push({
            label: computer.name,
            value: computer.id,
            type: 'computer'
          });
        }
      }

      return pondList;
    },
    scrollHeight() {
      const listHeight = this.$q.screen.xs
        ? this.farmsFiltered.length * 100
        : this.farmsFiltered.length * 63;
      const availHeight = window.innerHeight - 230;

      return listHeight < availHeight ? listHeight : availHeight;
    }
  }
};
</script>

<style scoped></style>
