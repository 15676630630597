import { render, staticRenderFns } from "./App.vue?vue&type=template&id=9cb83718&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=9cb83718&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb83718",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QResizeObserver,QHeader,QSeparator,QToolbar,QBtn,QSpace,QToolbarTitle,QBreadcrumbs,QBreadcrumbsEl,QSpinnerIos,QBadge,QIcon,QDrawer,QList,QItem,QItemSection,QAvatar,QItemLabel,QToggle,QPageContainer,QDialog,QCard,QCardSection,QInput,QForm,QSelect});
